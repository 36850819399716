import {
  CenteredCell,
  ColorPatchCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  ColumnTitleConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { match } from "ts-pattern";
import { UltimateBeneficialOwnersFragment } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { BooleanTag } from "./BooleanTag";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  reexecuteQuery: () => void;
};

type Edge = UltimateBeneficialOwnersFragment;

type Props = {
  ultimateBeneficialOwners: Array<Edge>;
  extraInfo: ExtraInfo;
  emptyListTitle: string;
};

const keyExtractor = (_: Edge, index: number) => String(index);

const stickedToStartColumns = [
  {
    width: 4,
    id: "color",
    title: "Color",
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <ColorPatchCell color="current" isHovered={isHovered} />
    ),
  },

  {
    width: 500,
    id: "name",
    title: t("ultimateBeneficialOwner.name"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { firstName, lastName } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(firstName) && isNotNullish(lastName) ? (
        <SimpleTitleCell text={`${firstName} ${lastName}`} />
      ) : null,
  },
];
const columns = [
  {
    width: 150,
    id: "birthDate",
    title: t("ultimateBeneficialOwner.birthDate"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { birthDate } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(birthDate) ? (
        <SimpleRegularTextCell text={dayjs(birthDate).format(locale.dateFormat)} />
      ) : null,
  },
  {
    width: 170,
    id: "birthPostalCode",
    title: t("ultimateBeneficialOwner.birthPostalCode"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { birthCityPostalCode } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(birthCityPostalCode) ? (
        <SimpleRegularTextCell text={birthCityPostalCode} />
      ) : null,
  },
  {
    width: 200,
    id: "birthCity",
    title: t("ultimateBeneficialOwner.birthCity"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { birthCity } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(birthCity) ? <SimpleRegularTextCell text={birthCity} /> : null,
  },
  {
    width: 150,
    id: "birthCountry",
    title: t("ultimateBeneficialOwner.birthCountry"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { birthCountryCode } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(birthCountryCode) ? <SimpleRegularTextCell text={birthCountryCode} /> : null,
  },
  {
    width: 200,
    id: "indirectOwnership",
    title: t("ultimateBeneficialOwner.indirectOwnership"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { info } }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        {match(info)
          .with(
            { __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" },
            ({ indirect }) => {
              if (isNotNullish(indirect)) {
                return <BooleanTag value={indirect} />;
              }
              return null;
            },
          )
          .otherwise(() => null)}
      </CenteredCell>
    ),
  },
  {
    width: 180,
    id: "directOwnership",
    title: t("ultimateBeneficialOwner.directOwnership"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { info } }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        {match(info)
          .with({ __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" }, ({ direct }) => {
            if (isNotNullish(direct)) {
              return <BooleanTag value={direct} />;
            }
            return null;
          })
          .otherwise(() => null)}
      </CenteredCell>
    ),
  },
  {
    width: 150,
    id: "capitalHeld",
    title: t("ultimateBeneficialOwner.capitalHeld"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { info } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      match(info)
        .with(
          { __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" },
          ({ totalCapitalPercentage }) => {
            if (isNotNullish(totalCapitalPercentage)) {
              return <SimpleRegularTextCell text={`${totalCapitalPercentage}%`} />;
            }
            return null;
          },
        )
        .otherwise(() => null),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 180,
    id: "type",
    title: t("ultimateBeneficialOwner.type"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell text={title} justifyContent="flex-end" />
    ),
    renderCell: ({
      item: {
        info: { type },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        {match(type)
          .with("LegalRepresentative", value => <Tag color="darkPink">{value}</Tag>)
          .with("HasCapital", value => <Tag color="live">{value}</Tag>)
          .with("Other", value => <Tag color="gray">{value}</Tag>)
          .exhaustive()}
      </CenteredCell>
    ),
  },
];

export const UltimateBeneficialOwnerList = ({
  ultimateBeneficialOwners,
  extraInfo,
  emptyListTitle,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={ultimateBeneficialOwners}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      headerHeight={48}
      rowHeight={48}
      renderEmptyList={() => <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />}
    />
  );
};
