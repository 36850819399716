import {
  BalanceCell,
  CellAction,
  ColorPatchCell,
  CopyableRegularTextCell,
  EndAlignedCell,
  LinkCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { P, match } from "ts-pattern";
import { AccountListFragment, AccountOrderByInput } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Router } from "../utils/routes";
import { TrackPressable } from "./TrackPressable";

export type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountOrderByInput) => void;
  sortBy?: AccountOrderByInput;
  reexecuteQuery: () => void;
};

type Edge = GetEdge<AccountListFragment>;

type Props = {
  accounts: AccountListFragment["edges"];
  onEndReached: () => void;
  hasSearchOrFilters?: boolean;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  emptyListTitle: string;
  stickedToStartColumns?: ColumnName[];
  columns?: ColumnName[];
  stickedToEndColumns?: ColumnName[];
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const availableColumns = {
  Color: {
    width: 4,
    id: "color",
    title: "Color",
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <ColorPatchCell color="current" isHovered={isHovered} />
    ),
  },
  Number: {
    width: 180,
    id: "number",
    title: t("account.number"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { number },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <SimpleTitleCell text={number} />,
  },
  Name: {
    width: 150,
    id: "name",
    title: t("account.name"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { name },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <SimpleRegularTextCell text={name} />,
  },
  Holder: {
    width: 240,
    id: "holder",
    title: t("account.accountHolder"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          holder: {
            id: accountHolderId,
            info: { name },
          },
        },
      },
      extraInfo: { projectEnv, projectId },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <LinkCell
        onPress={() => Router.push("HoldersDetailRoot", { projectId, projectEnv, accountHolderId })}
      >
        {name}
      </LinkCell>
    ),
  },
  Id: {
    width: 240,
    id: "id",
    title: t("account.id"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CopyableRegularTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  PaymentLevel: {
    width: 150,
    id: "paymentLevel",
    title: t("account.paymentLevel"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentLevel },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <StartAlignedCell>
        {match(paymentLevel)
          .with("Limited", () => (
            <Tag color="warning">{t("accounts.filters.paymentLevels.limited")}</Tag>
          ))
          .with("Unlimited", () => (
            <Tag color="positive">{t("accounts.filters.paymentLevels.unlimited")}</Tag>
          ))
          .with(P.nullish, () => null)
          .exhaustive()}
      </StartAlignedCell>
    ),
  },
  AccountType: {
    width: 150,
    id: "accountType",
    title: t("account.accountType"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentAccountType },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <StartAlignedCell>
        {match(paymentAccountType)
          .with("EMoney", () => (
            <Tag color="shakespear">{t("accounts.paymentAccountType.eMoney")}</Tag>
          ))
          .with("PaymentService", () => (
            <Tag color="positive">{t("accounts.paymentAccountType.PaymentService")}</Tag>
          ))
          .with(P.nullish, () => null)
          .exhaustive()}
      </StartAlignedCell>
    ),
  },
  Status: {
    width: 150,
    id: "status",
    title: t("account.status"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <StartAlignedCell>
        {match(status)
          .with("Opened", () => <Tag color="positive">{t("account.status.opened")}</Tag>)
          .with("Suspended", () => <Tag color="negative">{t("account.status.suspended")}</Tag>)
          .with("Closing", () => <Tag color="warning">{t("account.status.closing")}</Tag>)
          .with("Closed", () => <Tag color="gray">{t("account.status.closed")}</Tag>)
          .exhaustive()}
      </StartAlignedCell>
    ),
  },
  Iban: {
    width: 340,
    id: "iban",
    title: t("account.iban"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { IBAN },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(IBAN) ? (
        <CopyableRegularTextCell
          text={printMaskedIbanFormat(IBAN)}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      ) : null,
  },
  CreatedAt: {
    width: 200,
    id: "createdAt",
    title: t("account.createdAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort accounts by creation date">
        <SimpleHeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <SimpleRegularTextCell
        text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  UpdatedAt: {
    width: 200,
    id: "updatedAt",
    title: t("account.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort accounts by update date">
        <SimpleHeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <SimpleRegularTextCell
        text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },

  AvailableBalance: {
    width: 180,
    id: "availableBalance",
    title: t("account.availableBalance"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell text={title} justifyContent="flex-end" />
    ),
    renderCell: ({
      item: {
        node: { balances },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(balances) ? (
        <BalanceCell
          value={Number(balances.available.value)}
          currency={balances.available.currency}
          formatCurrency={formatCurrency}
        />
      ) : null,
  },
  BookedBalance: {
    width: 180,
    id: "bookedBalance",
    title: t("account.bookedBalance"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell text={title} justifyContent="flex-end" />
    ),
    renderCell: ({
      item: {
        node: { balances },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(balances) ? (
        <BalanceCell
          value={Number(balances.booked.value)}
          currency={balances.booked.currency}
          formatCurrency={formatCurrency}
        />
      ) : null,
  },
  Actions: {
    width: 48,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <EndAlignedCell>
        <CellAction>
          <Icon
            name="chevron-right-filled"
            color={isHovered ? colors.gray[700] : colors.gray[200]}
            size={16}
          />
        </CellAction>
      </EndAlignedCell>
    ),
  },
};

type ColumnName = keyof typeof availableColumns;

const defaultStickedToStartColumns: ColumnName[] = ["Color", "Number"];

const defaultColumns: ColumnName[] = [
  "Name",
  "Holder",
  "Id",
  "PaymentLevel",
  "AccountType",
  "Status",
  "Iban",
  "CreatedAt",
  "UpdatedAt",
];
const defaultStickedToEndColumns: ColumnName[] = ["AvailableBalance", "BookedBalance", "Actions"];

export const AccountList = ({
  accounts,
  onEndReached,
  isLoading,
  perPage,
  hasSearchOrFilters = false,
  extraInfo,
  getRowLink,
  emptyListTitle,
  stickedToStartColumns = defaultStickedToStartColumns,
  columns = defaultColumns,
  stickedToEndColumns = defaultStickedToEndColumns,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={accounts}
      stickedToStartColumns={stickedToStartColumns.map(id => availableColumns[id])}
      columns={columns.map(id => availableColumns[id])}
      stickedToEndColumns={stickedToEndColumns.map(id => availableColumns[id])}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView
            icon="lake-inbox-empty"
            title={emptyListTitle}
            subtitle={t("accounts.list.empty.subtitle")}
          />
        )
      }
    />
  );
};
