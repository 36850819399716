import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  CopyableRegularTextCell,
  EndAlignedCell,
  SimpleHeaderCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings, texts } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { ReactNode, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import {
  AddVirtualIbanDocument,
  GetAccountVirtualIbanListDocument,
  GetAccountVirtualIbanListQuery,
} from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatNestedMessage, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Connection } from "./Connection";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  link: {
    fontWeight: texts.semibold.fontWeight,
    textDecorationLine: "underline",
  },
});

type Edge = GetEdge<NonNullable<GetAccountVirtualIbanListQuery["account"]>["virtualIbanEntries"]>;

type Props = {
  accountId: string;
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

const PER_PAGE = 20;

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("virtualIbans.id"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableRegularTextCell
        text={node.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 370,
    id: "iban",
    title: t("virtualIbans.iban"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableRegularTextCell
        text={printMaskedIbanFormat(node.IBAN)}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 200,
    id: "bic",
    title: t("virtualIbans.bic"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableRegularTextCell
        text={node.BIC}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "status",
    title: t("virtualIbans.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item: { node } }) => (
      <EndAlignedCell>
        <LakeText color={colors.gray[900]}>
          {match(node.status)
            .with("Enabled", () => <Tag color="positive">{t("virtualIbans.status.enabled")}</Tag>)
            .with("Canceled", () => <Tag color="warning">{t("virtualIbans.status.canceled")}</Tag>)
            .with("Suspended", () => (
              <Tag color="warning">{t("virtualIbans.status.suspended")}</Tag>
            ))
            .exhaustive()}
        </LakeText>
      </EndAlignedCell>
    ),
  },
];

export const AccountDetailVirtualIbanList = ({ accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canCreateVirtualIbans = usePermissions(projectEnv).dataAccount.write;

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountVirtualIbanListDocument, {
    accountId,
    first: PER_PAGE,
  });

  const [addVirtualIban, virtualIbanAddition] = useMutation(AddVirtualIbanDocument);

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId };
  }, [projectEnv, projectId]);

  const onNewVirtualIban = () => {
    addVirtualIban({ accountId })
      .mapOk(data => data.addVirtualIbanEntry)
      .mapOkToResult(data => Option.fromNullable(data).toResult(new Error("Empty")))
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        reload();
        showToast({ variant: "success", title: t("virtualIbans.new.success") });
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ account }) => account?.virtualIbanEntries.totalCount ?? 0);

  const shouldShowTopbar = data
    .toOption()
    .flatMap(result =>
      result.map(({ account }) => (account?.virtualIbanEntries.edges.length ?? 0) > 0).toOption(),
    )
    .getOr(true);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      {shouldShowTopbar ? (
        <Box direction="row" alignItems="center">
          <TrackPressable action="New virtual IBAN">
            <LakeTooltip
              placement="left"
              content={t("common.action.denied")}
              disabled={canCreateVirtualIbans}
            >
              <LakeButton
                size="small"
                icon="add-circle-filled"
                loading={virtualIbanAddition.isLoading()}
                onPress={onNewVirtualIban}
                color="current"
                disabled={!canCreateVirtualIbans}
              >
                {t("virtualIbans.new")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>

          <Space width={16} />

          <TrackPressable action="Refresh virtual IBANs list">
            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              loading={isRefreshing}
              onPress={() => {
                setIsRefreshing(true);
                reload().tap(() => setIsRefreshing(false));
              }}
            />
          </TrackPressable>

          <Fill minWidth={16} />

          {totalCount
            .map<ReactNode>(count => (
              <>
                <Tag size="large" color="partner">
                  {t("virtualIbans.counter", { count })}
                </Tag>

                <Space width={16} />
              </>
            ))
            .toNull()}
        </Box>
      ) : null}

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data?.account?.virtualIbanEntries}>
            {virtualIbanEntries => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={virtualIbanEntries?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                onEndReached={() => {
                  if (virtualIbanEntries?.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: virtualIbanEntries?.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                loading={{
                  isLoading,
                  count: 20,
                }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-inbox-empty"
                    title={t("virtualIbans.empty.title")}
                    subtitle={
                      data.account?.paymentLevel === "Unlimited"
                        ? formatNestedMessage("virtualIbans.empty.subtitle.canCreateIban", {
                            learnMore: (
                              <Link
                                style={styles.link}
                                target="blank"
                                to="https://swan.io/blog-posts/eliminate-the-tedious-task-and-ongoing-resources-required-for-accounts-reconciliation-heres-how"
                              >
                                {t("common.learnMore")}
                              </Link>
                            ),
                            createFirstIban: (
                              <LakeText variant="semibold">
                                {t("virtualIbans.empty.createFirstIban")}
                              </LakeText>
                            ),
                          })
                        : formatNestedMessage("virtualIbans.empty.subtitle", {
                            learnMore: (
                              <Link
                                style={styles.link}
                                target="blank"
                                to="https://swan.io/blog-posts/eliminate-the-tedious-task-and-ongoing-resources-required-for-accounts-reconciliation-heres-how"
                              >
                                {t("common.learnMore")}
                              </Link>
                            ),
                          })
                    }
                  >
                    {data.account?.paymentLevel === "Unlimited" && (
                      <LakeButtonGroup justifyContent="center">
                        <TrackPressable action="New virtual IBAN">
                          <LakeButton
                            size="small"
                            icon="add-circle-filled"
                            loading={virtualIbanAddition.isLoading()}
                            onPress={onNewVirtualIban}
                            color="current"
                          >
                            {t("virtualIbans.new")}
                          </LakeButton>
                        </TrackPressable>
                      </LakeButtonGroup>
                    )}
                  </EmptyView>
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
