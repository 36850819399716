import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { Flag } from "@swan-io/shared-business/src/components/Flag";
import { getCountryByCCA3, isCountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import dayjs from "dayjs";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { GetAccountMembershipQuery } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { BooleanTag } from "./BooleanTag";

type Props = {
  accountMembership: NonNullable<GetAccountMembershipQuery["accountMembership"]>;
};

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const AccountMembershipDetailGeneral = ({ accountMembership }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();

  const { account, language, user } = accountMembership;
  const membershipStatus = accountMembership.statusInfo.status;
  const accountMembershipAddress = accountMembership.residencyAddress;

  const isExpert = user?.identificationLevels?.expert === true;
  const isQES = user?.identificationLevels?.QES === true;
  const isPVID = user?.identificationLevels?.PVID === true;

  return (
    <>
      {match(accountMembership.statusInfo)
        .with({ __typename: "AccountMembershipBindingUserErrorStatusInfo" }, statusInfo => (
          <>
            <LakeAlert title={t("accountMembership.bindingUserError")} variant="error">
              <View role="list">
                {statusInfo.birthDateMatchError ? (
                  <View>
                    <LakeText color={colors.gray[900]}>
                      {t("accountMembership.bindingUserError.birthDateMatchError")}
                    </LakeText>
                  </View>
                ) : null}

                {statusInfo.firstNameMatchError ? (
                  <View>
                    <LakeText color={colors.gray[900]}>
                      {t("accountMembership.bindingUserError.firstNameMatchError")}
                    </LakeText>
                  </View>
                ) : null}

                {statusInfo.lastNameMatchError ? (
                  <View>
                    <LakeText color={colors.gray[900]}>
                      {t("accountMembership.bindingUserError.lastNameMatchError")}
                    </LakeText>
                  </View>
                ) : null}

                {statusInfo.idVerifiedMatchError ? (
                  <View>
                    <LakeText color={colors.gray[900]}>
                      {t("accountMembership.bindingUserError.idVerifiedMatchError")}
                    </LakeText>
                  </View>
                ) : null}

                {statusInfo.phoneNumberMatchError ? (
                  <View>
                    <LakeText color={colors.gray[900]}>
                      {t("accountMembership.bindingUserError.phoneNumberMatchError")}
                    </LakeText>
                  </View>
                ) : null}
              </View>
            </LakeAlert>

            <Space height={24} />
          </>
        ))

        .otherwise(() => null)}

      <TileGrid>
        <Tile title={t("accountMembership.accountMembership")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("accountMembership.email")}
              type="view"
              color="current"
              render={() => <LakeText color={colors.gray[900]}>{accountMembership.email}</LakeText>}
            />

            {isNotNullish(language) && (
              <LakeLabel
                label={t("accountMembership.language")}
                type="view"
                color="current"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {match(language)
                      .with("de", () => t("accountMembership.language.de"))
                      .with("en", () => t("accountMembership.language.en"))
                      .with("es", () => t("accountMembership.language.es"))
                      .with("fr", () => t("accountMembership.language.fr"))
                      .with("it", () => t("accountMembership.language.it"))
                      .with("nl", () => t("accountMembership.language.nl"))
                      .with("pt", () => t("accountMembership.language.pt"))
                      .exhaustive()}
                  </LakeText>
                )}
              />
            )}

            <LakeLabel
              label={t("accountMembership.id")}
              type="view"
              color="current"
              render={() => <LakeText color={colors.gray[900]}>{accountMembership.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={accountMembership.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              label={t("accountMembership.status")}
              type="view"
              color="current"
              render={() =>
                match(accountMembership.statusInfo.status)
                  .with("ConsentPending", value => <Tag color="gray">{value}</Tag>)
                  .with("InvitationSent", value => <Tag color="shakespear">{value}</Tag>)
                  .with("BindingUserError", value => <Tag color="negative">{value}</Tag>)
                  .with("Enabled", value => <Tag color="positive">{value}</Tag>)
                  .with("Suspended", value => <Tag color="warning">{value}</Tag>)
                  .with("Disabled", value => <Tag color="gray">{value}</Tag>)
                  .exhaustive()
              }
            />

            <LakeLabel
              label={t("accountMembership.account")}
              type="view"
              render={() =>
                isNotNullish(account) ? (
                  <LakeText color={colors.gray[900]}>{account.name}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
              actions={
                isNotNullish(account) ? (
                  <Link
                    to={Router.AccountDetailRoot({ projectId, projectEnv, accountId: account.id })}
                  >
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                ) : null
              }
            />

            <LakeLabel
              label={t("accountMembership.accountHolder")}
              type="view"
              render={() =>
                isNotNullish(account) ? (
                  <LakeText color={colors.gray[900]}>{account.holder.info.name}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
              actions={
                isNotNullish(account) ? (
                  <Link
                    to={Router.HoldersDetailRoot({
                      projectId,
                      projectEnv,
                      accountHolderId: account.holder.id,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                ) : null
              }
            />

            <LakeLabel
              label={t("accountMembership.createdAt")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(accountMembership.createdAt).format(
                    `${locale.dateFormat} ${locale.timeFormat}`,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("accountMembership.updatedAt")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(accountMembership.updatedAt).format(
                    `${locale.dateFormat} ${locale.timeFormat}`,
                  )}
                </LakeText>
              )}
            />
          </ReadOnlyFieldList>
        </Tile>

        {account?.country === "DEU" && (
          <Tile title={t("accountMembership.address.title")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("accountMembership.address")}
                type="view"
                color="current"
                render={() => (
                  <View>
                    {isNotNullishOrEmpty(accountMembershipAddress?.postalCode) ? (
                      <LakeText color={colors.gray[900]}>
                        {accountMembershipAddress?.addressLine1 ?? UNKNOWN_VALUE}
                      </LakeText>
                    ) : (
                      UNKNOWN_VALUE
                    )}

                    {isNotNullishOrEmpty(accountMembershipAddress?.addressLine2) ? (
                      <LakeText color={colors.gray[900]}>
                        {accountMembershipAddress?.addressLine2}
                      </LakeText>
                    ) : null}
                  </View>
                )}
              />

              <LakeLabel
                label={t("accountMembership.address.postalCode")}
                type="view"
                color="current"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {isNotNullishOrEmpty(accountMembershipAddress?.postalCode)
                      ? accountMembershipAddress?.postalCode
                      : UNKNOWN_VALUE}
                  </LakeText>
                )}
              />

              <LakeLabel
                label={t("accountMembership.address.city")}
                type="view"
                color="current"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {isNotNullishOrEmpty(accountMembershipAddress?.city)
                      ? accountMembershipAddress?.city
                      : UNKNOWN_VALUE}
                  </LakeText>
                )}
              />

              <LakeLabel
                label={t("accountMembership.address.country")}
                type="view"
                color="current"
                render={() => {
                  const cca3 = accountMembershipAddress?.country;

                  if (isCountryCCA3(cca3)) {
                    const country = getCountryByCCA3(cca3);

                    return (
                      <Box direction="row" alignItems="center">
                        <Flag code={country.cca2} width={14} />
                        <Space width={8} />
                        <LakeText color={colors.gray[900]}>{country.name}</LakeText>
                      </Box>
                    );
                  } else {
                    return UNKNOWN_VALUE;
                  }
                }}
              />
            </ReadOnlyFieldList>
          </Tile>
        )}

        {isNotNullish(user) ? (
          <Tile title={t("accountMembership.user")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("accountMembership.user.phoneNumber")}
                type="view"
                render={() =>
                  isNotNullishOrEmpty(user.mobilePhoneNumber) ? (
                    <LakeText color={colors.gray[900]}>{user.mobilePhoneNumber}</LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                label={t("accountMembership.user.firstName")}
                type="view"
                render={() =>
                  isNotNullishOrEmpty(user.firstName) ? (
                    <LakeText color={colors.gray[900]}>{user.firstName}</LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                label={t("accountMembership.user.lastName")}
                type="view"
                render={() =>
                  isNotNullishOrEmpty(user.preferredLastName) ? (
                    <LakeText color={colors.gray[900]}>{user.preferredLastName}</LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                label={t("accountMembership.user.birthDate")}
                type="view"
                render={() =>
                  isNotNullishOrEmpty(user.birthDate) ? (
                    <LakeText color={colors.gray[900]}>{user.birthDate}</LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                label={t("accountHolder.legalRepresentative.identificationLevels")}
                type="view"
                color="current"
                render={() => (
                  <Box direction="row" alignItems="center">
                    <Tag
                      label={t("accountHolder.legalRepresentative.identificationLevels.expert")}
                      color={isExpert ? "positive" : "gray"}
                    >
                      {isExpert ? t("common.true") : t("common.false")}
                    </Tag>

                    <Space width={12} />

                    <Tag
                      label={t("accountHolder.legalRepresentative.identificationLevels.qes")}
                      color={isQES ? "positive" : "gray"}
                    >
                      {isQES ? t("common.true") : t("common.false")}
                    </Tag>

                    <Space width={12} />

                    <Tag
                      label={t("accountHolder.legalRepresentative.identificationLevels.pvid")}
                      color={isPVID ? "positive" : "gray"}
                    >
                      {isPVID ? t("common.true") : t("common.false")}
                    </Tag>
                  </Box>
                )}
                actions={
                  <LakeButton
                    ariaLabel={t("common.moreInfo")}
                    mode="tertiary"
                    icon="arrow-right-regular"
                    href={Router.UserDetailIdentifications({
                      projectId,
                      projectEnv,
                      userId: user.id,
                    })}
                    onPress={event => {
                      event.preventDefault();
                      Router.push("UserDetailIdentifications", {
                        projectId,
                        projectEnv,
                        userId: user.id,
                      });
                    }}
                  />
                }
              />
            </ReadOnlyFieldList>
          </Tile>
        ) : null}

        {membershipStatus === "Enabled" ||
        membershipStatus === "InvitationSent" ||
        membershipStatus === "ConsentPending" ? (
          <Tile title={t("accountMembership.rights")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("accountMembership.legalRepresentative")}
                type="view"
                render={() => <BooleanTag value={accountMembership.legalRepresentative} />}
              />

              <LakeLabel
                label={t("accountMembership.canViewAccount")}
                type="view"
                render={() => <BooleanTag value={accountMembership.canViewAccount} />}
              />

              <LakeLabel
                label={t("accountMembership.canInitiatePayments")}
                type="view"
                render={() => <BooleanTag value={accountMembership.canInitiatePayments} />}
              />

              <LakeLabel
                label={t("accountMembership.canManageBeneficiaries")}
                type="view"
                render={() => <BooleanTag value={accountMembership.canManageBeneficiaries} />}
              />

              <LakeLabel
                label={t("accountMembership.canManageAccountMembership")}
                type="view"
                render={() => <BooleanTag value={accountMembership.canManageAccountMembership} />}
              />

              <LakeLabel
                label={t("accountMembership.canManageCards")}
                type="view"
                render={() => <BooleanTag value={accountMembership.canManageCards} />}
              />
            </ReadOnlyFieldList>
          </Tile>
        ) : null}

        {match(accountMembership.statusInfo)
          .with(
            { __typename: "AccountMembershipInvitationSentStatusInfo" },
            { __typename: "AccountMembershipBindingUserErrorStatusInfo" },
            { __typename: "AccountMembershipConsentPendingStatusInfo" },
            ({ restrictedTo }) => (
              <Tile title={t("accountMembership.restrictedTo")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("accountMembership.user.phoneNumber")}
                    type="view"
                    render={() =>
                      isNotNullishOrEmpty(restrictedTo.phoneNumber) ? (
                        <LakeText color={colors.gray[900]}>{restrictedTo.phoneNumber}</LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                  />

                  <LakeLabel
                    label={t("accountMembership.user.firstName")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>{restrictedTo.firstName}</LakeText>
                    )}
                  />

                  <LakeLabel
                    label={t("accountMembership.user.lastName")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>{restrictedTo.lastName}</LakeText>
                    )}
                  />

                  <LakeLabel
                    label={t("accountMembership.user.birthDate")}
                    type="view"
                    render={() =>
                      isNotNullishOrEmpty(restrictedTo.birthDate) ? (
                        <LakeText color={colors.gray[900]}>{restrictedTo.birthDate}</LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                  />
                </ReadOnlyFieldList>
              </Tile>
            ),
          )
          .otherwise(() => null)}
      </TileGrid>
    </>
  );
};
