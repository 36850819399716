import { Box } from "@swan-io/lake/src/components/Box";
import {
  CellAction,
  CenteredCell,
  ColorPatchCell,
  CopyableRegularTextCell,
  EndAlignedCell,
  LinkCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { match } from "ts-pattern";
import { CardListFragment, CardOrderByInput } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { Card, Edge } from "../utils/card";
import { locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { BooleanTag } from "./BooleanTag";
import { TrackPressable } from "./TrackPressable";

type ExtraInfo = {
  projectId: string;
  projectEnv: ProjectEnv;
  onCancelCard: (card: Card) => void;
  onChangeSort?: (sortBy: CardOrderByInput) => void;
  sortBy?: CardOrderByInput;
  reexecuteQuery: () => void;
  canCancelCardContracts: boolean;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 4,
    id: "color",
    title: "Color",
    renderTitle: () => null,
    renderCell: ({ isHovered }) => <ColorPatchCell color="current" isHovered={isHovered} />,
  },
  {
    width: 230,
    id: "type",
    title: t("cards.list.type"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }) => {
      return (
        <StartAlignedCell>
          {match(type)
            .with("SingleUseVirtual", () => (
              <Tag color="darkPink" icon="phone-regular">
                {t("card.type.singleUseVirtual")}
              </Tag>
            ))
            .with("Virtual", () => (
              <Tag color="mediumSladeBlue" icon="phone-regular">
                {t("card.type.virtual")}
              </Tag>
            ))
            .with("VirtualAndPhysical", () => (
              <Tag color="shakespear" icon="payment-regular">
                {t("card.type.virtualAndPhysical")}
              </Tag>
            ))
            .exhaustive()}
        </StartAlignedCell>
      );
    },
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "cardHolder",
    title: t("cards.list.cardHolder"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { accountMembership },
      },
      extraInfo: { projectEnv, projectId },
    }) => {
      const accountId = accountMembership.account?.id ?? null;

      return (
        isNotNullish(accountId) && (
          <LinkCell
            onPress={() =>
              Router.push("AccountMembershipsDetailRoot", {
                projectId,
                projectEnv,
                accountMembershipId: accountMembership.id,
              })
            }
          >
            {match(accountMembership.statusInfo)
              .with(
                { __typename: "AccountMembershipConsentPendingStatusInfo" },
                { __typename: "AccountMembershipInvitationSentStatusInfo" },
                status => `${status.restrictedTo.firstName} ${status.restrictedTo.lastName}`,
              )
              .otherwise(() => accountMembership.user?.fullName ?? "")}
          </LinkCell>
        )
      );
    },
  },
  {
    width: 250,
    id: "account",
    title: t("cards.list.account"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { accountMembership },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      const accountId = accountMembership.account?.id ?? null;
      const accountName = `${accountMembership.account?.name ?? ""} - ${
        accountMembership.account?.number ?? ""
      }`;

      return (
        isNotNullish(accountId) &&
        isNotNullish(accountName) && (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {accountName}
          </LinkCell>
        )
      );
    },
  },
  {
    width: 200,
    id: "accountHolder",
    title: t("cards.list.accountHolder"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { accountMembership },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      const accountHolderId = accountMembership.account?.holder.id;
      const accountHolderName = accountMembership.account?.holder.info.name;

      return (
        isNotNullish(accountHolderId) &&
        isNotNullish(accountHolderName) && (
          <LinkCell
            onPress={() =>
              Router.push("HoldersDetailRoot", {
                projectId,
                projectEnv,
                accountHolderId,
              })
            }
          >
            {accountHolderName}
          </LinkCell>
        )
      );
    },
  },
  {
    width: 400,
    id: "id",
    title: t("cards.list.id"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableRegularTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 120,
    id: "name",
    title: t("cards.list.name"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { name },
      },
    }) => <SimpleRegularTextCell text={name ?? "-"} />,
  },
  {
    width: 200,
    id: "cardContractExpiryDate",
    title: t("cards.list.expirationDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { cardContractExpiryDate },
      },
    }) =>
      isNotNullish(cardContractExpiryDate) ? (
        <SimpleRegularTextCell textAlign="center" text={cardContractExpiryDate} />
      ) : (
        <StartAlignedCell>
          <Tag color="gray">{t("cards.list.unlimited")}</Tag>
        </StartAlignedCell>
      ),
  },
  {
    width: 300,
    id: "createdAt",
    title: t("cards.list.createdAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort cards by creation date">
        <SimpleHeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => (
      <SimpleRegularTextCell
        text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 300,
    id: "updatedAt",
    title: t("cards.list.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort cards by update date">
        <SimpleHeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => (
      <SimpleRegularTextCell
        text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 250,
    id: "eCommerce",
    title: t("cards.list.eCommerce"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="center" text={title} />,
    renderCell: ({
      item: {
        node: { eCommerce },
      },
    }) => (
      <CenteredCell>
        <BooleanTag value={eCommerce} />
      </CenteredCell>
    ),
  },
  {
    width: 250,
    id: "international",
    title: t("cards.list.international"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="center" text={title} />,
    renderCell: ({
      item: {
        node: { international },
      },
    }) => (
      <CenteredCell>
        <BooleanTag value={international} />
      </CenteredCell>
    ),
  },
  {
    width: 220,
    id: "nonMainCurrencyTransactions",
    title: t("cards.list.nonEuro"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="center" text={title} />,
    renderCell: ({
      item: {
        node: { nonMainCurrencyTransactions },
      },
    }) => (
      <CenteredCell>
        <BooleanTag value={nonMainCurrencyTransactions} />
      </CenteredCell>
    ),
  },
  {
    width: 120,
    id: "withdrawal",
    title: t("cards.list.withdrawal"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="center" text={title} />,
    renderCell: ({
      item: {
        node: { withdrawal },
      },
    }) => (
      <CenteredCell>
        <BooleanTag value={withdrawal} />
      </CenteredCell>
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "status",
    title: t("cards.list.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => {
      return (
        <EndAlignedCell>
          <LakeText color={colors.gray[900]}>
            {match(statusInfo.status)
              .with("ConsentPending", () => (
                <Tag color="gray">{t("cards.list.status.consentPending")}</Tag>
              ))
              .with("Processing", () => (
                <Tag color="shakespear">{t("cards.list.status.processing")}</Tag>
              ))
              .with("Enabled", () => <Tag color="positive">{t("cards.list.status.enabled")}</Tag>)
              .with("Canceling", () => (
                <Tag color="warning">{t("cards.list.status.canceling")}</Tag>
              ))
              .with("Canceled", () => <Tag color="warning">{t("cards.list.status.canceled")}</Tag>)
              .exhaustive()}
          </LakeText>
        </EndAlignedCell>
      );
    },
  },
  {
    width: 100,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({ isHovered, item, extraInfo: { canCancelCardContracts, onCancelCard } }) => {
      return (
        <EndAlignedCell>
          <CellAction>
            <Box direction="row">
              {item.node.statusInfo.status !== "Canceled" &&
                item.node.statusInfo.status !== "Canceling" && (
                  <LakeTooltip
                    placement="right"
                    content={t("common.action.denied")}
                    disabled={canCancelCardContracts}
                  >
                    <Pressable
                      onPress={event => {
                        event.preventDefault();
                        onCancelCard(item.node);
                      }}
                      disabled={!canCancelCardContracts}
                    >
                      {({ hovered }) => (
                        <Icon
                          name="subtract-circle-regular"
                          color={hovered ? colors.negative[500] : colors.gray[200]}
                          size={16}
                        />
                      )}
                    </Pressable>

                    <Space width={8} />
                  </LakeTooltip>
                )}

              <Icon
                name="chevron-right-filled"
                color={isHovered ? colors.gray[700] : colors.gray[200]}
                size={16}
              />
            </Box>
          </CellAction>
        </EndAlignedCell>
      );
    },
  },
];

type Props = {
  cards: CardListFragment["edges"];
  onEndReached: () => void;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  hasSearchOrFilters: boolean;
  emptyListTitle: string;
};

export const CardList = ({
  cards,
  onEndReached,
  perPage,
  isLoading,
  extraInfo,
  getRowLink,
  hasSearchOrFilters,
  emptyListTitle,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      data={cards}
      keyExtractor={keyExtractor}
      extraInfo={extraInfo}
      columns={columns}
      onEndReached={onEndReached}
      stickedToStartColumns={stickedToStartColumns}
      stickedToEndColumns={stickedToEndColumns}
      headerHeight={48}
      loading={{
        isLoading,
        count: perPage,
      }}
      rowHeight={48}
      getRowLink={getRowLink}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />
        )
      }
    />
  );
};
