import { AsyncData, Dict, Future, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  CellAction,
  ColorPatchCell,
  CopyableRegularTextCell,
  EndAlignedCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { GetNode } from "@swan-io/lake/src/utils/types";
import {
  FilterDateDef,
  FilterInputDef,
  FilterRadioDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import { toOptionalValidator } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import {
  WebhookEventLogsDocument,
  WebhookEventLogsQuery,
  WebhookSubscriptionQuery,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { validateDate, validateId } from "../utils/validations";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const NUM_EVENT_LOGS_PER_PAGE = 20;

type Props = {
  webhookSubscriptionId: string;
  webhookEventTypes: string[];
  params: RouteParams["DevelopersWebhooksSubscriptionEventLogs"];
};

type WebhookSubscription = NonNullable<WebhookSubscriptionQuery["webhookSubscription"]>;

type WebhookEvent = GetNode<NonNullable<WebhookEventLogsQuery["webhookEventLogs"]>>;

type WebhookStatus = "Success" | "Failure";

const statusTextFilter: FilterRadioDef<WebhookStatus | undefined> = {
  type: "radio",
  label: t("webhook.filters.status.label"),
  items: [
    { value: undefined, label: t("webhook.filters.all") },
    { value: "Success", label: t("webhook.filters.status.succeeded") },
    { value: "Failure", label: t("webhook.filters.status.failed") },
  ],
};

const resourceIdFilter: FilterInputDef = {
  type: "input",
  label: t("webhook.table.resourceId"),
  placeholder: t("webhook.table.resourceId.placeholder"),
  noValueText: t("common.none"),
};

const eventTypeFilter: FilterRadioDef<WebhookSubscription["eventTypes"][number] | undefined> = {
  type: "radio",
  label: t("webhook.filters.eventType.label"),
  items: [],
};

const eventIdFilter: FilterInputDef = {
  type: "input",
  label: t("webhook.table.eventId"),
  placeholder: t("webhook.table.id.placeholder"),
  noValueText: t("common.none"),
  validate: validateId,
};

const startDateFilter: FilterDateDef = {
  type: "date",
  label: t("webhook.filters.startDate.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: toOptionalValidator(validateDate),
};

const endAtFilter: FilterDateDef = {
  type: "date",
  label: t("webhook.filters.endDate.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: toOptionalValidator(validateDate),
};

const filtersDefinition = {
  statusText: statusTextFilter,
  resourceId: resourceIdFilter,
  eventId: eventIdFilter,
  eventType: eventTypeFilter,
  startDate: startDateFilter,
  endDate: endAtFilter,
};

type WebhookEventsFilters = FiltersState<typeof filtersDefinition>;

type EventsFiltersFormProps = {
  filters: WebhookEventsFilters;
  onChangeFilters: (filters: WebhookEventsFilters) => void;
  onRefresh: () => Future<unknown>;
  eventTypes: WebhookSubscription["eventTypes"];
};

const EventsFiltersForm = ({
  filters,
  onChangeFilters,
  onRefresh,
  eventTypes,
}: EventsFiltersFormProps) => {
  const availableFilters: { name: keyof WebhookEventsFilters; label: string }[] = useMemo(
    () => [
      {
        name: "statusText",
        label: t("webhook.filters.status.label"),
      },
      {
        name: "eventType",
        label: t("webhook.filters.eventType.label"),
      },
      {
        name: "startDate",
        label: t("webhook.filters.startDate.label"),
      },
      {
        name: "endDate",
        label: t("webhook.filters.endDate.label"),
      },
      {
        name: "resourceId",
        label: t("webhook.table.resourceId"),
      },
      {
        name: "eventId",
        label: t("webhook.table.eventId"),
      },
    ],
    [],
  );

  // As per UX request, the fields you select should remain on screen, even if the popover
  // is dismissed and the value unchanged.
  // We also want to preserve the insertion order through the component lifecycle.

  // That's why we maintain the list in a state cell:
  // - The initial state is taken from the URL
  // - When `filters` from URL change, we add the ones not yet tracked at the end of the array
  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([_name, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  // Add event types depending on webhook config
  const definitionWithEventTypes = useMemo(
    () => ({
      ...filtersDefinition,
      eventType: {
        ...filtersDefinition.eventType,
        items: [
          { value: undefined, label: t("webhook.filters.all") },
          ...eventTypes.map(eventType => ({ value: eventType, label: eventType })),
        ],
      },
    }),
    [eventTypes],
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" justifyContent="start">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          title={t("webhook.filters.choose")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Refresh webhook events list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={definitionWithEventTypes}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </View>
  );
};

const keyExtractor = ({ id }: WebhookEvent) => id;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  webhookSubscriptionId: string;
  reexecuteQuery: () => void;
};
type Edge = WebhookEvent;

const getRowLink = ({
  item: { id },
  extraInfo: { projectEnv, projectId, webhookSubscriptionId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link
    to={Router.DevelopersWebhooksSubscriptionEventLog({
      projectId,
      projectEnv,
      webhookSubscriptionId,
      logId: id,
    })}
  />
);

const stickedToStartColumns = [
  {
    width: 4,
    id: "color",
    title: "Color",
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <ColorPatchCell color="current" isHovered={isHovered} />
    ),
  },
  {
    width: 340,
    id: "eventType",
    title: t("webhook.table.eventType"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { eventType } }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <SimpleTitleCell text={eventType} />
    ),
  },
];
const columns = [
  {
    width: 300,
    id: "eventId",
    title: t("webhook.table.eventId"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { eventId } }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CopyableRegularTextCell
        text={eventId}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
];
const stickedToEndColumns = [
  {
    width: 250,
    id: "dateTime",
    title: t("webhook.table.createdAt"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell justifyContent="flex-end" text={title} />
    ),
    renderCell: ({ item: { createdAt } }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <SimpleRegularTextCell
        textAlign="right"
        text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 120,
    id: "status",
    title: t("webhook.table.statusCode"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell justifyContent="flex-end" text={title} />
    ),
    renderCell: ({ item: { statusCode } }: ColumnCellConfig<Edge, ExtraInfo>) =>
      typeof statusCode === "number" && (
        <EndAlignedCell>
          {statusCode !== 0 && statusCode < 300 ? (
            <Tag color="positive">{`${statusCode} OK`}</Tag>
          ) : (
            <Tag color="negative">{`${statusCode} NOK`}</Tag>
          )}
        </EndAlignedCell>
      ),
  },
  {
    width: 100,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell justifyContent="flex-end" text={title} />
    ),
    renderCell: () => (
      <EndAlignedCell>
        <CellAction>
          <Icon name="chevron-right-filled" color={colors.gray[200]} size={16} />
        </CellAction>
      </EndAlignedCell>
    ),
  },
];

export const WebhookSubscriptionEventLogs = ({
  webhookSubscriptionId,
  webhookEventTypes,
  params,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  // The following pattern matching is used to correctly type the values,
  // incorrect values are set as `undefined` (aka. unset).
  const filters: WebhookEventsFilters = useMemo(() => {
    return {
      statusText: match(params.statusText)
        .with("Success", "Failure", identity)
        .otherwise(() => undefined),
      eventType: match(params.eventType)
        .with(P.string, identity)
        .otherwise(() => undefined),
      endDate: match(params.endDate)
        .with(P.string, identity)
        .otherwise(() => undefined),
      startDate: match(params.startDate)
        .with(P.string, identity)
        .otherwise(() => undefined),
      resourceId: match(params.resourceId)
        .with(P.string, identity)
        .otherwise(() => undefined),
      eventId: match(params.eventId)
        .with(P.string, identity)
        .otherwise(() => undefined),
    } as const;
  }, [
    params.statusText,
    params.eventType,
    params.endDate,
    params.startDate,
    params.resourceId,
    params.eventId,
  ]);

  const hasFilters = Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(WebhookEventLogsDocument, {
    first: NUM_EVENT_LOGS_PER_PAGE,
    filters: { ...filters, webhookSubscriptionId },
  });

  const extraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      webhookSubscriptionId,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, webhookSubscriptionId, reload]);

  return (
    <>
      <EventsFiltersForm
        filters={filters}
        onChangeFilters={filters =>
          Router.replace("DevelopersWebhooksSubscriptionEventLogs", {
            projectId,
            projectEnv,
            webhookSubscriptionId,
            ...filters,
          })
        }
        onRefresh={reload}
        eventTypes={webhookEventTypes}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={NUM_EVENT_LOGS_PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.webhookEventLogs}>
            {webhookEventlog =>
              isNullish(webhookEventlog) ? (
                <EmptyView
                  icon="clipboard-search-regular"
                  title={t("common.list.noResults")}
                  subtitle={t("common.list.noResultsSuggestion")}
                />
              ) : (
                <VirtualizedList
                  variant="default"
                  marginHorizontal={negativeSpacings[24]}
                  extraInfo={extraInfo}
                  keyExtractor={keyExtractor}
                  data={webhookEventlog.edges.map(item => item.node)}
                  stickedToStartColumns={stickedToStartColumns}
                  columns={columns}
                  stickedToEndColumns={stickedToEndColumns}
                  onEndReached={() => {
                    if (webhookEventlog.pageInfo.hasNextPage === true) {
                      setVariables({ after: webhookEventlog.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  headerHeight={48}
                  rowHeight={48}
                  getRowLink={getRowLink}
                  loading={{
                    isLoading,
                    count: NUM_EVENT_LOGS_PER_PAGE,
                  }}
                  renderEmptyList={() =>
                    hasFilters ? (
                      <EmptyView
                        icon="clipboard-search-regular"
                        title={t("common.list.noResults")}
                        subtitle={t("common.list.noResultsSuggestion")}
                      />
                    ) : (
                      <EmptyView
                        icon="lake-inbox-empty"
                        title={t("webhook.tableEmpty.title")}
                        subtitle={t("webhook.tableEmpty.message")}
                      />
                    )
                  }
                />
              )
            }
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
