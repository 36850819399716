import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import {
  BalanceCell,
  CopyableRegularTextCell,
  EndAlignedCell,
  LinkCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Link } from "@swan-io/lake/src/components/Link";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { printFormat } from "iban";
import { useMemo } from "react";
import { View } from "react-native";
import { match, P } from "ts-pattern";
import { UserAccountMembershipFragment, UserAccountMembershipsDocument } from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { AccountStatusTag, VerificationStatusTag } from "./BusinessTags";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { MembershipPermissionsTag } from "./MembershipPermissionsTag";

const PER_PAGE = 20;

type ExtraInfo = { projectEnv: ProjectEnv; projectId: string };

const keyExtractor = (node: UserAccountMembershipFragment) => node.id;

const getRowLink = ({
  item: { account },
  extraInfo: { projectId, projectEnv },
}: LinkConfig<UserAccountMembershipFragment, ExtraInfo>) =>
  Option.fromNullable(account)
    .map(({ id }) => (
      <Link to={Router.AccountDetailRoot({ accountId: id, projectId, projectEnv })} />
    ))
    .getOr(<View />);

const stickedToStartColumns: ColumnConfig<UserAccountMembershipFragment, ExtraInfo>[] = [
  {
    id: "accountHolder",
    width: 250,
    title: t("user.details.accounts.table.accountHolder"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { account }, extraInfo: { projectEnv, projectId } }) =>
      Option.fromNullable(account)
        .map(({ holder }) => (
          <LinkCell
            onPress={() => {
              Router.push("HoldersDetailRoot", {
                projectId,
                projectEnv,
                accountHolderId: holder.id,
              });
            }}
            tooltip={{ placement: "right", content: holder.info.name }}
          >
            {holder.info.name}
          </LinkCell>
        ))
        .toNull(),
  },
];

const columns: ColumnConfig<UserAccountMembershipFragment, ExtraInfo>[] = [
  {
    id: "iban",
    width: 360,
    title: t("user.details.accounts.table.iban"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) =>
      Option.fromNullable(item.account)
        .flatMap(({ IBAN }) => Option.fromNullable(IBAN))
        .map(IBAN => (
          <CopyableRegularTextCell
            text={printFormat(IBAN)}
            textToCopy={IBAN}
            copyWording={t("copyButton.copyTooltip")}
            copiedWording={t("copyButton.copiedTooltip")}
            tooltip={{ placement: "right", content: IBAN }}
          />
        ))
        .getOr(
          <SimpleRegularTextCell
            color={colors.gray[500]}
            text={t("user.details.accounts.table.iban.noIban")}
          />,
        ),
  },
  {
    id: "availableBalance",
    width: 170,
    title: t("user.details.accounts.table.availableBalance"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) =>
      Option.fromNullable(item.account)
        .flatMap(({ balances }) => Option.fromNullable(balances))
        .map(balances => (
          <BalanceCell
            value={Number(balances.available.value)}
            currency={balances.available.currency}
            formatCurrency={formatCurrency}
            textAlign="left"
          />
        ))
        .toNull(),
  },
  {
    id: "accountLanguage",
    width: 150,
    title: t("user.details.accounts.table.accountLanguage"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) =>
      Option.fromNullable(item.account)
        .map(({ language }) => (
          <SimpleRegularTextCell
            text={match(language)
              .with("de", () => t("user.details.language.german"))
              .with("en", () => t("user.details.language.english"))
              .with("es", () => t("user.details.language.spanish"))
              .with("fr", () => t("user.details.language.french"))
              .with("it", () => t("user.details.language.italian"))
              .with("nl", () => t("user.details.language.dutch"))
              .with("pt", () => t("user.details.language.portuguese"))
              .otherwise(() => t("common.unknown"))}
          />
        ))
        .toNull(),
  },
  {
    id: "permissions",
    width: 200,
    title: t("user.details.accounts.table.permissions"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item }) => (
      <EndAlignedCell>
        <MembershipPermissionsTag accountMembership={item} />
      </EndAlignedCell>
    ),
  },
  {
    id: "verificationStatus",
    width: 220,
    title: t("user.details.accounts.table.verificationStatus"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item }) =>
      Option.fromNullable(item.account)
        .flatMap(account => Option.fromNullable(account.holder))
        .map(({ verificationStatus }) => (
          <EndAlignedCell>
            <VerificationStatusTag verificationStatus={verificationStatus} />
          </EndAlignedCell>
        ))
        .toNull(),
  },
  {
    id: "accountStatus",
    width: 190,
    title: t("user.details.accounts.table.accountStatus"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item }) =>
      Option.fromNullable(item.account)
        .map(({ statusInfo: { status } }) => (
          <EndAlignedCell>
            <AccountStatusTag accountStatus={status} />
          </EndAlignedCell>
        ))
        .toNull(),
  },
  {
    id: "actions",
    width: 60,
    title: "",
    renderTitle: () => null,
    renderCell: () => (
      <EndAlignedCell>
        <Icon name="arrow-right-filled" color={colors.gray[900]} size={16} />
      </EndAlignedCell>
    ),
  },
];

type Props = {
  userId: string;
};

export const UserDetailAccounts = ({ userId }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();
  const [data, { isLoading, setVariables }] = useQuery(UserAccountMembershipsDocument, {
    userId,
    first: PER_PAGE,
  });

  const extraInfo = useMemo(
    () => ({
      projectEnv,
      projectId,
    }),
    [projectEnv, projectId],
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <VirtualizedListPlaceholder
        headerHeight={48}
        rowHeight={48}
        count={20}
        marginHorizontal={negativeSpacings[24]}
      />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
      <Connection connection={data.user?.accountMemberships}>
        {accountMemberships => (
          <VirtualizedList
            variant="default"
            marginHorizontal={negativeSpacings[24]}
            data={accountMemberships?.edges.map(edge => edge.node) ?? []}
            keyExtractor={keyExtractor}
            extraInfo={extraInfo}
            stickedToStartColumns={stickedToStartColumns}
            getRowLink={getRowLink}
            columns={columns}
            onEndReached={() => {
              if (accountMemberships?.pageInfo.hasNextPage === true) {
                setVariables({ after: accountMemberships?.pageInfo.endCursor ?? undefined });
              }
            }}
            headerHeight={48}
            rowHeight={48}
            loading={{ isLoading, count: PER_PAGE }}
            renderEmptyList={() => (
              <EmptyView icon="lake-inbox-empty" title={t("common.list.noResults")} />
            )}
          />
        )}
      </Connection>
    ))
    .exhaustive();
};
