import { AsyncData, Result } from "@swan-io/boxed";
import { Link } from "@swan-io/chicane";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  CellAction,
  CopyableRegularTextCell,
  EndAlignedCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactNode, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { GetInvoicesDocument, GetInvoicesQuery } from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Connection } from "./Connection";
import { TrackPressable } from "./TrackPressable";

type Edge = GetEdge<NonNullable<NonNullable<GetInvoicesQuery["account"]>["invoices"]>>;

type Props = {
  accountId: string;
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

const PER_PAGE = 20;

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 180,
    id: "openingDate",
    title: t("invoices.openingDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={dayjs(node.openingDate).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 180,
    id: "closingDate",
    title: t("invoices.closingDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={dayjs(node.closingDate).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("invoices.id"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableRegularTextCell
        text={node.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 120,
    id: "type",
    title: t("transaction.type"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }) => (
      <StartAlignedCell>
        {match(type)
          .with("Invoice", value => <Tag color="shakespear">{value}</Tag>)
          .with("RefundNote", value => <Tag color="darkPink">{value}</Tag>)
          .exhaustive()}
      </StartAlignedCell>
    ),
  },
  {
    width: 120,
    id: "status",
    title: t("transaction.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { status },
      },
    }) => (
      <StartAlignedCell>
        {match(status)
          .with("Failed", value => <Tag color="negative">{value}</Tag>)
          .with("NotPaid", value => <Tag color="negative">{value}</Tag>)
          .with("Paid", value => <Tag color="positive">{value}</Tag>)
          .with("PaymentDue", value => <Tag color="warning">{value}</Tag>)
          .with("Pending", value => <Tag color="shakespear">{value}</Tag>)
          .with("Voided", value => <Tag color="gray">{value}</Tag>)
          .exhaustive()}
      </StartAlignedCell>
    ),
  },

  {
    width: 120,
    id: "amount",
    title: t("invoices.amount"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={formatCurrency(Number(node.amount.value), node.amount.currency)}
      />
    ),
  },
  {
    width: 200,
    id: "createdAt",
    title: t("invoices.createdAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={dayjs(node.createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("invoices.updatedAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={dayjs(node.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 60,
    id: "download",
    title: t("invoices.download"),
    renderTitle: () => null,
    renderCell: ({
      item: {
        node: { url, status },
      },
    }) => {
      return (
        <EndAlignedCell>
          {isNotNullish(url) &&
          (status === "Paid" || status === "NotPaid" || status === "PaymentDue") ? (
            <Link target="blank" to={url} download={true}>
              <CellAction>
                <Icon color={colors.gray[500]} size={18} name="arrow-download-filled" />
              </CellAction>
            </Link>
          ) : (
            <CellAction>
              <LakeTooltip
                content={t("invoices.noDocumentTooltip")}
                placement="right"
                togglableOnFocus={true}
                hideArrow={true}
              >
                <Icon
                  color={colors.gray[300]}
                  size={18}
                  name="arrow-download-filled"
                  tabIndex={0}
                />
              </LakeTooltip>
            </CellAction>
          )}
        </EndAlignedCell>
      );
    },
  },
];

export const AccountDetailInvoicesList = ({ accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data, { isLoading, reload, setVariables }] = useQuery(GetInvoicesDocument, {
    accountId,
    first: PER_PAGE,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId };
  }, [projectEnv, projectId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ account }) => account?.invoices?.totalCount ?? 0);

  const shouldShowTopbar = data
    .toOption()
    .flatMap(result =>
      result.map(({ account }) => (account?.invoices?.edges.length ?? 0) > 0).toOption(),
    )
    .getOr(true);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      {shouldShowTopbar ? (
        <Box direction="row" alignItems="center">
          <TrackPressable action="Refresh invoices list">
            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              loading={isRefreshing}
              onPress={() => {
                setIsRefreshing(true);
                reload().tap(() => setIsRefreshing(false));
              }}
            />
          </TrackPressable>

          <Fill minWidth={16} />

          {totalCount
            .map<ReactNode>(count => (
              <>
                <Tag size="large" color="partner">
                  {t("invoices.counter", { count })}
                </Tag>

                <Space width={16} />
              </>
            ))
            .toNull()}
        </Box>
      ) : null}

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data?.account?.invoices}>
            {invoices => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={invoices?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                onEndReached={() => {
                  if (invoices?.pageInfo.hasNextPage === true) {
                    setVariables({ after: invoices.pageInfo.endCursor ?? undefined });
                  }
                }}
                loading={{
                  isLoading,
                  count: 20,
                }}
                renderEmptyList={() => (
                  <EmptyView icon="lake-inbox-empty" title={t("invoices.empty.title")} />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
