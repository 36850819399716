import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  CellAction,
  CopyableRegularTextCell,
  EndAlignedCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactNode, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import {
  GetReceivedDirectDebitMandatesDocument,
  GetReceivedDirectDebitMandatesQuery,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Router } from "../utils/routes";
import { Connection } from "./Connection";
import { TrackPressable } from "./TrackPressable";

type Props = {
  accountId: string;
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  accountId: string;
};

type Edge = GetEdge<
  NonNullable<
    NonNullable<GetReceivedDirectDebitMandatesQuery["account"]>["receivedDirectDebitMandates"]
  >
>;

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId, accountId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link
    to={Router.AccountDetailReceivedDirectDebitMandatesDetail({
      projectId,
      projectEnv,
      accountId,
      receivedDirectDebitMandateId: id,
    })}
  />
);

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "creditorName",
    title: t("receivedDirectDebitMandates.creditorName"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => <SimpleTitleCell text={node.creditor.name} />,
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 250,
    id: "creditorIdentifier",
    title: t("receivedDirectDebitMandates.creditorIdentifier"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => <SimpleRegularTextCell text={node.creditor.identifier} />,
  },
  {
    width: 250,
    id: "ultimateCreditorName",
    title: t("receivedDirectDebitMandates.ultimateCreditorName"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell text={node.ultimateCreditorName ?? ""} />
    ),
  },
  {
    width: 430,
    id: "id",
    title: t("receivedDirectDebitMandates.id"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableRegularTextCell
        text={node.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 300,
    id: "scheme",
    title: t("receivedDirectDebitMandates.scheme"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      const scheme = match(node)
        .with(
          { __typename: "InternalReceivedDirectDebitMandate" },
          ({ internalScheme }) => internalScheme,
        )
        .with({ __typename: "SEPAReceivedDirectDebitMandate" }, ({ sepaScheme }) => sepaScheme)
        .exhaustive();

      return (
        <StartAlignedCell>
          {match(scheme)
            .with("InternalDirectDebitB2b", () => (
              <Tag color="darkPink">{t("receivedDirectDebitMandates.scheme.internalB2b")}</Tag>
            ))
            .with("SepaDirectDebitB2b", () => (
              <Tag color="darkPink">{t("receivedDirectDebitMandates.scheme.sepaB2b")}</Tag>
            ))
            .with("InternalDirectDebitStandard", () => (
              <Tag color="live">{t("receivedDirectDebitMandates.scheme.internalStandard")}</Tag>
            ))
            .with("SepaDirectDebitCore", () => (
              <Tag color="live">{t("receivedDirectDebitMandates.scheme.core")}</Tag>
            ))
            .exhaustive()}
        </StartAlignedCell>
      );
    },
  },
  {
    width: 350,
    id: "reference",
    title: t("receivedDirectDebitMandates.reference"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => <SimpleRegularTextCell text={node.reference} />,
  },
  {
    width: 100,
    id: "version",
    title: t("receivedDirectDebitMandates.version"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => <SimpleRegularTextCell text={node.version} />,
  },
  {
    width: 200,
    id: "signatureDate",
    title: t("receivedDirectDebitMandates.signatureDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) =>
      isNotNullish(node.signatureDate) ? (
        <SimpleRegularTextCell
          text={dayjs(node.signatureDate).format(`${locale.dateFormat} ${locale.timeFormat}`)}
        />
      ) : null,
  },
  {
    width: 350,
    id: "iban",
    title: t("receivedDirectDebitMandates.iban"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableRegularTextCell
        text={printMaskedIbanFormat(node.iban)}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 200,
    id: "createdAt",
    title: t("receivedDirectDebitMandates.creationDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={dayjs(node.createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 200,
    id: "executedAt",
    title: t("receivedDirectDebitMandates.lastExecDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) =>
      isNotNullish(node.executedAt) ? (
        <SimpleRegularTextCell
          text={dayjs(node.executedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
        />
      ) : null,
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("receivedDirectDebitMandates.lastUpdateDate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <SimpleRegularTextCell
        text={dayjs(node.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "status",
    title: t("receivedDirectDebitMandates.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item: { node } }) => (
      <EndAlignedCell>
        {match(node.statusInfo.status)
          .with("Enabled", () => (
            <Tag color="positive">{t("receivedDirectDebitMandates.status.enabled")}</Tag>
          ))
          .with("Canceled", () => (
            <Tag color="gray">{t("receivedDirectDebitMandates.status.canceled")}</Tag>
          ))
          .with("ConsentInitiationPending", () => (
            <Tag color="gray">
              {t("receivedDirectDebitMandates.status.consentInitiationPending")}
            </Tag>
          ))
          .with("ConsentPending", () => (
            <Tag color="shakespear">{t("receivedDirectDebitMandates.status.consentPending")}</Tag>
          ))
          .with("Suspended", () => (
            <Tag color="warning">{t("receivedDirectDebitMandates.status.suspended")}</Tag>
          ))
          .exhaustive()}
      </EndAlignedCell>
    ),
  },
  {
    width: 48,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({ isHovered }) => (
      <EndAlignedCell>
        <CellAction>
          <Icon
            name="chevron-right-filled"
            color={isHovered ? colors.gray[700] : colors.gray[200]}
            size={16}
          />
        </CellAction>
      </EndAlignedCell>
    ),
  },
];

const keyExtractor = ({ node }: Edge) => node.id;

const PER_PAGE = 20;

export const AccountDetailReceivedDirectDebitMandateList = ({ accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data, { isLoading, reload, setVariables }] = useQuery(
    GetReceivedDirectDebitMandatesDocument,
    { accountId, first: PER_PAGE },
  );

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId, accountId };
  }, [projectEnv, projectId, accountId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ account }) => account?.receivedDirectDebitMandates?.totalCount ?? 0);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row">
        <TrackPressable action="Refresh received direct debit mandate list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              reload().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        {totalCount
          .map<ReactNode>(count => (
            <Tag size="large" color="partner">
              {t("receivedDirectDebitMandates.counter", { count })}
            </Tag>
          ))
          .toNull()}
      </Box>

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data?.account?.receivedDirectDebitMandates}>
            {receivedDirectDebitMandates => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={receivedDirectDebitMandates?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                getRowLink={getRowLink}
                rowHeight={48}
                onEndReached={() => {
                  if (receivedDirectDebitMandates?.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: receivedDirectDebitMandates?.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                loading={{
                  isLoading,
                  count: 20,
                }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-inbox-empty"
                    title={t("receivedDirectDebitMandates.empty.title")}
                    subtitle={t("receivedDirectDebitMandates.empty.subtitle")}
                  />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
