import {
  ColorPatchCell,
  CopyableRegularTextCell,
  LinkCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { match } from "ts-pattern";
import { CapitalDepositCaseOrderByInput, CapitalDepositsListFragment } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { TrackPressable } from "./TrackPressable";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: CapitalDepositCaseOrderByInput) => void;
  sortBy?: CapitalDepositCaseOrderByInput;
  reexecuteQuery: () => void;
};

type Edge = GetEdge<CapitalDepositsListFragment>;

type Props = {
  capitalDeposit: CapitalDepositsListFragment["edges"];
  onEndReached: () => void;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  hasSearchOrFilters: boolean;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 4,
    id: "color",
    title: "Color",
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <ColorPatchCell color="current" isHovered={isHovered} />
    ),
  },
  {
    width: 240,
    id: "companyName",
    title: t("capitalDeposit.companyName"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { companyName },
      },
    }) => <SimpleTitleCell text={companyName} />,
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("capitalDeposit.id"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableRegularTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 250,
    id: "companyOnboarding",
    title: t("capitalDeposit.companyOnboardingId"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { companyOnboarding },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(companyOnboarding)) {
        const onboardingId = companyOnboarding.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("OnboardingDetailRoot", { projectId, projectEnv, onboardingId })
            }
          >
            {companyOnboarding.id}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 250,
    id: "companyAccountId",
    title: t("capitalDeposit.companyAccountId"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { companyAccountId },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(companyAccountId)) {
        return (
          <LinkCell
            onPress={() =>
              Router.push("AccountDetailRoot", {
                projectId,
                projectEnv,
                accountId: companyAccountId,
              })
            }
          >
            {companyAccountId}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 240,
    id: "amount",
    title: t("capitalDeposit.amount"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { totalCapitalDepositAmount },
      },
    }) => (
      <SimpleRegularTextCell
        text={
          totalCapitalDepositAmount.value !== ""
            ? formatCurrency(
                Number(totalCapitalDepositAmount.value),
                totalCapitalDepositAmount.currency,
              )
            : "-"
        }
      />
    ),
  },
  {
    width: 180,
    id: "uploadedDocuments",
    title: t("capitalDeposit.uploadedDocuments"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { documents },
      },
    }) => {
      let numberOfValidatedDocuments = 0;
      documents.map(document => {
        if (
          document.statusInfo.status === "Validated" ||
          document.statusInfo.status === "Uploaded"
        ) {
          ++numberOfValidatedDocuments;
        }
      });
      return <SimpleRegularTextCell text={String(numberOfValidatedDocuments)} />;
    },
  },
  {
    width: 200,
    id: "createdAt",
    title: t("capitalDeposit.createdAt"),
    renderTitle: ({ title, extraInfo }) => {
      return (
        <TrackPressable action="Sort capital deposit by creation date">
          <SimpleHeaderCell
            onPress={direction => {
              extraInfo.onChangeSort?.({ field: "createdAt", direction });
            }}
            sort={
              extraInfo.sortBy?.field === "createdAt"
                ? (extraInfo.sortBy?.direction ?? undefined)
                : undefined
            }
            text={title}
          />
        </TrackPressable>
      );
    },
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => (
      <SimpleRegularTextCell
        text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("capitalDeposit.updatedAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort capital deposit by update date">
        <SimpleHeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => (
      <SimpleRegularTextCell
        text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 270,
    id: "status",
    title: t("capitalDeposit.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-start" />,
    renderCell: ({
      item: {
        node: { status },
      },
    }) => (
      <StartAlignedCell>
        {match(status)
          .with("WaitingForRequirements", () => (
            <Tag color="gray">{t("capitalDeposit.status.waitingForRequirements")}</Tag>
          ))
          .with("WaitingForRegisterExtract", () => (
            <Tag color="shakespear">{t("capitalDeposit.status.waitingForRegisterExtract")}</Tag>
          ))
          .with("Initiated", () => (
            <Tag color="negative">{t("capitalDeposit.status.initiated")}</Tag>
          ))
          .with("Canceled", () => <Tag color="positive">{t("capitalDeposit.status.canceled")}</Tag>)
          .with("Completed", () => (
            <Tag color="positive">{t("capitalDeposit.status.completed")}</Tag>
          ))
          .with("WaitingForShareDepositCertificate", () => (
            <Tag color="warning">
              {t("capitalDeposit.status.waitingForShareDepositCertificate")}
            </Tag>
          ))
          .with("WaitingForNotaryTransfer", () => (
            <Tag color="warning">{t("capitalDeposit.status.waitingForNotaryTransfer")}</Tag>
          ))
          .exhaustive()}
      </StartAlignedCell>
    ),
  },
];

export const CapitalDepositList = ({
  capitalDeposit,
  onEndReached,
  isLoading,
  perPage,
  extraInfo,
  getRowLink,
  hasSearchOrFilters,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={capitalDeposit}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView
            icon="lake-inbox-empty"
            title={t("capitalDeposit.list.empty.title")}
            subtitle={t("capitalDeposit.list.empty.subtitle")}
          />
        )
      }
    />
  );
};
